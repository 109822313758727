import React from "react"
import SendIcon from "mdi-react/SendIcon"

import UserIcon from "../user-icon/userIcon"
import Button from "../button/Button"
import TextArea from "../text-area/textArea"
import { mapIconNameToIconSource, className } from "../../utils/utils"
import "./forumTextBox.scss"

var ForumTextBox = props => (
  <div className={`forum-text-box-component ${props.className || ""}`}>
    <TextArea
      error={props.error}
      errorMessage={props.errorMessage}
      height="15rem"
      name={props.subTitle}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
    <div className={className({ "text-limit": true, error: props.error })}>
      <span className="value">
        {`${props.value.length} / ${props.maxLength} `}
      </span>
      characters used
    </div>
    <div className="publish">
      {props.user && (
        <UserIcon
          className="user-icon"
          iconBackground={props.user.iconBackground}
          icon={mapIconNameToIconSource[props.user.icon]}
        />
      )}
      <Button className="button" grey onClick={props.onSubmit}>
        <div className="label">{props.CTA}</div>
        <div className="icon">
          <SendIcon />
        </div>
      </Button>
    </div>
  </div>
)

export default ForumTextBox

import React, { useState, useEffect, useRef } from "react"
import CommentTextMultipleOutlineIcon from "mdi-react/CommentTextMultipleOutlineIcon"
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon"
import ThumbUpIcon from "mdi-react/ThumbUpIcon"
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon"
import DeleteIcon from "mdi-react/DeleteIcon"

import BorderTitle from "../border-title/borderTitle"
import Loader from "../loader/loader"
import UserIcon from "../user-icon/userIcon"
import ForumTextBox from "../forum-text-box/forumTextBox"
import Button from "../button/Button"
import {
  mapIconNameToIconSource,
  timeAgo,
  TIMESTAMP_RERENDER_TIMEOUT,
  MAX_COMMENT_LENGTH,
  FORUM_POSTS_SOCKET_NAME,
  FORUM_COMMENTS_SOCKET_NAME,
  LIKE_POST_REQUEST_TYPE,
  UNLIKE_POST_REQUEST_TYPE,
  VIEW_POST_REQUEST_TYPE,
  LIKE_COMMENT_REQUEST_TYPE,
  UNLIKE_COMMENT_REQUEST_TYPE,
  ADD_COMMENT_REQUEST_TYPE,
} from "../../utils/utils"
import "./forumCard.scss"

var ForumCard = props => {
  var EMPTY_COMMENT = { value: "", errorMessage: "" }

  var [open, setOpen] = useState(false)
  var [comment, setComment] = useState(EMPTY_COMMENT)
  var [lastUpdated, setLastUpdated] = useState(new Date())
  var [commentPreviewMaxChars, setCommentPreviewMaxChars] = useState(100)
  var commentRef = useRef(null)

  useEffect(() => {
    var interval = setInterval(
      () => setLastUpdated(new Date()),
      TIMESTAMP_RERENDER_TIMEOUT
    )
    var handleWindowResize = () => {
      setCommentPreviewMaxChars(window.innerWidth / 3)
    }
    window.addEventListener("resize", handleWindowResize)

    return function cleanup() {
      window.removeEventListener("resize", handleWindowResize)
      clearInterval(interval)
    }
  })

  function scrollToCommentRef() {
    window.scrollTo({
      behavior: "smooth",
      top: commentRef.current.offsetTop - 80,
    })
  }

  function onCommentChange(e) {
    var newValue = e.target.value
    var newCommentObject = { ...comment }
    if (newValue.length > MAX_COMMENT_LENGTH) {
      newCommentObject.errorMessage = `Your comment body has exceeded the maximum length of ${MAX_COMMENT_LENGTH} characters`
    } else {
      newCommentObject.errorMessage = ""
    }
    newCommentObject.value = newValue
    setComment(newCommentObject)
  }

  function onSubmitComment() {
    var valid = true
    if (comment.value.length <= 0) {
      var newCommentObject = { ...comment }
      newCommentObject.errorMessage = `Please write your comment body before submitting...`
      setComment(newCommentObject)
      valid = false
    }

    if (comment.value.length > MAX_COMMENT_LENGTH) {
      valid = false
    }

    if (valid) {
      props.socket.emit(FORUM_COMMENTS_SOCKET_NAME, {
        type: ADD_COMMENT_REQUEST_TYPE,
        data: {
          user: props.user._id,
          post: props._id,
          body: comment.value,
        },
      })
      props.setIsNewCommentLoading(true)
      setComment(EMPTY_COMMENT)
    }
  }

  function onDelete() {
    if (props.isComment) {
      props.deleteComment(props._id)
    } else {
      props.deletePost(props._id)
    }
  }

  function onLikeChange() {
    var socketName
    var requestType
    if (props.isComment) {
      socketName = FORUM_COMMENTS_SOCKET_NAME
      requestType = props.liked
        ? UNLIKE_COMMENT_REQUEST_TYPE
        : LIKE_COMMENT_REQUEST_TYPE
    } else {
      socketName = FORUM_POSTS_SOCKET_NAME
      requestType = props.liked
        ? UNLIKE_POST_REQUEST_TYPE
        : LIKE_POST_REQUEST_TYPE
    }
    props.setIsLikeLoading(true)
    props.socket.emit(socketName, { type: requestType, data: props._id })
  }

  function onOpenPost() {
    setOpen(!open)
    if (!props.isComment && !open) {
      props.socket.emit(FORUM_POSTS_SOCKET_NAME, {
        type: VIEW_POST_REQUEST_TYPE,
        data: props._id,
      })
    }
  }

  var stats = props.isComment
    ? [
        {
          icon: ThumbUpOutlineIcon,
          value: props.likes,
          label: "likes",
        },
      ]
    : [
        {
          icon: CommentTextMultipleOutlineIcon,
          value: props.comments.length,
          label: "comments",
        },
        {
          icon: ThumbUpOutlineIcon,
          value: props.likes,
          label: "likes",
        },
        {
          icon: EyeOutlineIcon,
          value: props.views,
          label: "views",
        },
      ]

  return (
    <div
      className={`forum-card-component ${props.className || ""} ${
        props.isComment ? " comment" : ""
      } ${open ? " open" : ""}`}
    >
      <div className="click-wrap" onClick={onOpenPost}>
        <div className="header">
          <div className="user-icon">
            <UserIcon
              className="icon"
              iconBackground={props.iconBackground}
              innerShadow
              icon={mapIconNameToIconSource[props.icon]}
            />
          </div>
          <div className="middle">
            <div className="title-mobile">{props.name.toUpperCase()}</div>
            <div className="title">{`${props.name.toUpperCase()} - ${
              props.title
            }`}</div>
            <div className="time">{timeAgo(props.timestamp, lastUpdated)}</div>
            <div className="stats">
              {stats.map(stat => (
                <div key={stat.label} className="stat">
                  <div className={`icon ${stat.label}`}>
                    <stat.icon />
                  </div>
                  <span className="value">{stat.value}</span>
                  <span className="label">{stat.label.toUpperCase()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mobile-body">
          <div className="title">{props.title}</div>
          {props.isComment ? (
            <div className="body">{props.body}</div>
          ) : (
            <div className="body">
              {open
                ? props.body
                : props.body.substring(0, commentPreviewMaxChars) + "..."}
            </div>
          )}
        </div>
        <div className="mobile-footer">
          <div className="time">{timeAgo(props.timestamp, lastUpdated)}</div>
          <div className="read-more">READ MORE</div>
        </div>
      </div>
      <div className="admin-panel">
        <Button className="admin-delete" onClick={onDelete}>
          <div className="label">
            {props.isComment ? "DELETE COMMENT" : "DELETE POST"}
          </div>
          <DeleteIcon className="icon" />
        </Button>
      </div>
      <div className="truncated-body-wrapper">
        <div className="truncated-body">{props.body}</div>
        <div className="buttons">
          {!props.isComment && (
            <Button
              className="button leave-comment"
              grey
              onClick={scrollToCommentRef}
            >
              <div className="label">LEAVE A COMMENT</div>
              <CommentTextMultipleOutlineIcon className="icon" />
            </Button>
          )}
          <div className="button-splitter" />
          <Button
            className="button"
            invert={props.liked}
            grey
            onClick={onLikeChange}
            disabled={props.isLikeLoading}
          >
            <div className="label">
              {props.liked ? "UN" : ""}
              {props.isComment ? "LIKE THIS COMMENT" : "LIKE THIS POST"}
            </div>
            {props.liked ? (
              <ThumbUpIcon className="icon" />
            ) : (
              <ThumbUpOutlineIcon className="icon" />
            )}
          </Button>
        </div>
        <div className="comments">
          {!props.isComment &&
            props.comments.map(comment => (
              <ForumCard
                key={comment._id}
                user={props.user}
                socket={props.socket}
                deleteComment={props.deleteComment}
                deletePost={props.deletePost}
                isLikeLoading={props.isLikeLoading}
                setIsLikeLoading={props.setIsLikeLoading}
                isComment
                {...comment}
              />
            ))}
        </div>

        {!props.isComment && (
          <div className="write-comment" ref={commentRef}>
            <BorderTitle>
              Write a Comment {String(props.isNewCommentLoading)}
            </BorderTitle>
            {props.isNewCommentLoading ? (
              <Loader className="loader" />
            ) : (
              <ForumTextBox
                value={comment.value}
                onChange={onCommentChange}
                onSubmit={onSubmitComment}
                error={comment.errorMessage.length > 0}
                errorMessage={comment.errorMessage}
                user={props.user}
                subTitle="Comment Body"
                placeholder="Write your comment here..."
                CTA="POST COMMENT"
                maxLength={MAX_COMMENT_LENGTH}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ForumCard

import React, { useState, useEffect, useRef } from "react"
import io from "socket.io-client"
import MagnifyIcon from "mdi-react/MagnifyIcon"
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon"
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import Input from "../components/input/input"
import ForumCard from "../components/forum-card/forumCard"
import Loader from "../components/loader/loader"
import BorderTitle from "../components/border-title/borderTitle"
import ForumTextBox from "../components/forum-text-box/forumTextBox"
import Toast from "../components/toast/toast"
import {
  className,
  FORUM_PAGE_SIZE,
  FORUM_PAGINATION_BUTTON_SIZE,
  BASE_URL,
  FORUM_POSTS_SOCKET_NAME,
  MAX_POST_LENGTH,
  TOAST_TIMER,
  MAX_POST_TITLE_LENGTH,
  ADD_POST_REQUEST_TYPE,
} from "../utils/utils"
import bg from "../images/banners/forum.jpg"

import "./forum.scss"

var Forum = props => {
  const EMPTY_POST = {
    bodyValue: "",
    bodyErrorMessage: "",
    titleValue: "",
    titleErrorMessage: "",
  }

  const EMPTY_TOASTS = {
    postPosted: false,
    commentPosted: false,
    error: false,
  }

  var [isPostsLoading, setIsPostsLoading] = useState(true)
  var [isNewPostLoading, setIsNewPostLoading] = useState(false)
  var [isNewCommentLoading, setIsNewCommentLoading] = useState(false)
  var [isLikeLoading, setIsLikeLoading] = useState(false)
  var [connectedToSocket, setConnectedToSocket] = useState(false)
  var [socket, setSocket] = useState(null)
  var [page, setPage] = useState(1)
  var [search, setSearch] = useState("")
  var [newPost, setNewPost] = useState(EMPTY_POST)
  var [toasts, setToasts] = useState(EMPTY_TOASTS)
  var [adminPosts, setAdminPosts] = useState([])
  var [userPosts, setUserPosts] = useState([])

  var firstPost = useRef(null)
  var isNewPostLoadingRef = useRef(isNewPostLoading)
  var isNewCommentLoadingRef = useRef(isNewCommentLoading)
  var isLikeLoadingRef = useRef(isLikeLoading)

  useEffect(() => {
    if (props.user != null && !connectedToSocket) {
      setConnectedToSocket(true)
      connectToSockets()
    }
    isNewPostLoadingRef.current = isNewPostLoading
    isNewCommentLoadingRef.current = isNewCommentLoading
    isLikeLoadingRef.current = isLikeLoading
  })

  function filterPostsBySearchTerm(post) {
    var postContent = `${post.name} ${post.title} ${post.body}`
    var commentContent = post.comments
      .map(comment => `${comment.name} ${comment.body}`)
      .reduce((sum, curr) => `${sum} ${curr}`, "")
    var content = postContent + commentContent
    return content.toLowerCase().includes(search.toLowerCase())
  }

  var pagination = []
  for (let i = 1; i <= FORUM_PAGINATION_BUTTON_SIZE; i++) {
    pagination.push(i)
  }

  function getLastPageNumber() {
    return Math.ceil(
      userPosts.filter(filterPostsBySearchTerm).length / FORUM_PAGE_SIZE
    )
  }

  function previousPage() {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  function nextPage() {
    if (page < getLastPageNumber()) {
      setPage(page + 1)
    }
  }

  function goToPage(pageNumber) {
    if (pageNumber <= getLastPageNumber()) {
      setPage(pageNumber)
    }
  }

  function PaginationNumberItem(props) {
    var skipPages = 0
    if (page > FORUM_PAGINATION_BUTTON_SIZE / 2) {
      skipPages = page - Math.floor(FORUM_PAGINATION_BUTTON_SIZE / 2)
    }
    var calculatedPageNumber = props.paginationItemNumber + skipPages
    return (
      <div
        className={className({
          "nav-item page": true,
          active: calculatedPageNumber === page,
          disabled: calculatedPageNumber > getLastPageNumber(),
        })}
        key={calculatedPageNumber}
        onClick={() => goToPage(calculatedPageNumber)}
      >
        <div className="page-background">{calculatedPageNumber}</div>
      </div>
    )
  }

  function connectToSockets() {
    function getForumPostsHandler() {
      socket.on(FORUM_POSTS_SOCKET_NAME, posts => {
        if (posts.error) {
          showToast("error")
          console.log("Error getting posts", posts.error)
          return
        }
        setIsPostsLoading(false)
        setAdminPosts(posts.adminPosts)
        setUserPosts(posts.userPosts)
        if (isNewPostLoadingRef.current) {
          setNewPost(EMPTY_POST)
          setIsNewPostLoading(false)
          scrollToFirstPost()
          showToast("postPosted")
        }
        if (isNewCommentLoadingRef.current) {
          setIsNewCommentLoading(false)
          showToast("commentPosted")
        }
        if (isLikeLoadingRef.current) {
          setIsLikeLoading(false)
        }
      })
    }

    const socket = io.connect(BASE_URL, {
      query: {
        token: props.user.token,
      },
    })
    setSocket(socket)
    getForumPostsHandler()
  }

  function showToast(toastName, time = TOAST_TIMER) {
    var newToastObj = { ...toasts }
    newToastObj[toastName] = true
    setToasts(newToastObj)
    setTimeout(() => setToasts(EMPTY_TOASTS), time)
  }

  function scrollToFirstPost() {
    window.scrollTo({
      behavior: "smooth",
      top: firstPost.current.offsetTop - 80,
    })
  }

  function onNewPostChange(input) {
    var maxLength
    var errorMessagePropertyName
    var valuePropertyName
    if (input === "body") {
      maxLength = MAX_POST_LENGTH
      errorMessagePropertyName = "bodyErrorMessage"
      valuePropertyName = "bodyValue"
    } else if (input === "title") {
      maxLength = MAX_POST_TITLE_LENGTH
      errorMessagePropertyName = "titleErrorMessage"
      valuePropertyName = "titleValue"
    }
    return function onTitleChange(e) {
      var newValue = e.target.value
      var newPostObject = { ...newPost }
      if (newValue.length > maxLength) {
        newPostObject[
          errorMessagePropertyName
        ] = `Your post ${input} has exceeded the maximum length of ${maxLength} characters`
      } else {
        newPostObject[errorMessagePropertyName] = ""
      }
      newPostObject[valuePropertyName] = newValue
      setNewPost(newPostObject)
    }
  }

  function submitPost() {
    var isValid = true
    var newPostObj = { ...newPost }
    if (newPost.bodyValue.length <= 0) {
      newPostObj.bodyErrorMessage =
        "Please write your post body before submitting..."
      isValid = false
    }
    if (newPost.titleValue.length <= 0) {
      newPostObj.titleErrorMessage =
        "Please write your post title before submitting..."
      isValid = false
    }
    if (
      newPost.bodyValue.length > MAX_POST_LENGTH ||
      newPost.titleValue.length > MAX_POST_TITLE_LENGTH
    ) {
      isValid = false
    }
    setNewPost(newPostObj)

    if (isValid) {
      setIsNewPostLoading(true)
      socket.emit(FORUM_POSTS_SOCKET_NAME, {
        type: ADD_POST_REQUEST_TYPE,
        data: {
          title: newPost.titleValue,
          body: newPost.bodyValue,
          user: props.user._id,
        },
      })
    }
  }

  return (
    <>
      <SEO title="Home" keywords={["gatsby", "application", "react"]} />
      <div
        className={className({
          "forum-page": true,
          "admin-mode": props.adminMode,
        })}
      >
        <Banner
          src={bg}
          title="Forum"
          subTitle=""
          height="27.5rem"
          backgroundPosY="20%"
        />
        <PageBody className="page">
          {isPostsLoading ? (
            <Loader className="loader" />
          ) : (
            <React.Fragment>
              <div className="search">
                <div className="icon">
                  <MagnifyIcon />
                </div>
                <input
                  className="input"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search posts..."
                />
              </div>
              <div className="admin-posts">
                <BorderTitle>PINNED ADMIN POSTS</BorderTitle>
                {adminPosts.length > 0 ? (
                  adminPosts
                    .filter(filterPostsBySearchTerm)
                    .map(post => (
                      <ForumCard
                        key={post._id}
                        user={props.user}
                        isNewCommentLoading={isNewCommentLoading}
                        setIsNewCommentLoading={setIsNewCommentLoading}
                        isLikeLoading={isLikeLoading}
                        setIsLikeLoading={setIsLikeLoading}
                        socket={socket}
                        deleteComment={props.deleteComment}
                        deletePost={props.deletePost}
                        {...post}
                      />
                    ))
                ) : (
                  <div className="no-posts">No Admin Posts Found</div>
                )}
              </div>
              <div className="user-posts" ref={firstPost}>
                <BorderTitle>PARTICIPANT QUESTIONS & ANSWERS</BorderTitle>
                {userPosts.length > 0 ? (
                  userPosts
                    .filter(filterPostsBySearchTerm)
                    .slice(
                      0 + FORUM_PAGE_SIZE * (page - 1),
                      FORUM_PAGE_SIZE + (page - 1) * FORUM_PAGE_SIZE
                    )
                    .map(post => (
                      <ForumCard
                        key={post._id}
                        user={props.user}
                        isNewCommentLoading={isNewCommentLoading}
                        setIsNewCommentLoading={setIsNewCommentLoading}
                        isLikeLoading={isLikeLoading}
                        setIsLikeLoading={setIsLikeLoading}
                        deleteComment={props.deleteComment}
                        deletePost={props.deletePost}
                        socket={socket}
                        {...post}
                      />
                    ))
                ) : (
                  <div className="no-posts">No Participant Posts Found</div>
                )}
                <div className="pagination-navigator">
                  <div className="nav-item icon" onClick={() => setPage(1)}>
                    <ChevronDoubleLeftIcon />
                  </div>
                  <div className="nav-item icon" onClick={previousPage}>
                    <ChevronLeftIcon />
                  </div>
                  {pagination.map(paginationItemNumber => (
                    <PaginationNumberItem
                      key={paginationItemNumber}
                      paginationItemNumber={paginationItemNumber}
                    />
                  ))}
                  <div className="nav-item icon" onClick={nextPage}>
                    <ChevronRightIcon />
                  </div>
                  <div className="nav-item icon">
                    <ChevronDoubleRightIcon
                      onClick={() => setPage(getLastPageNumber())}
                    />
                  </div>
                </div>
              </div>
              {isNewPostLoading ? (
                <Loader className="loader" />
              ) : (
                <div className="write-post">
                  <BorderTitle>Write a Post</BorderTitle>
                  <Input
                    className="title-input"
                    error={newPost.titleErrorMessage.length > 0}
                    errorMessage={newPost.titleErrorMessage}
                    name="Post Title"
                    placeholder="Write your post title here..."
                    value={newPost.titleValue}
                    onChange={onNewPostChange("title")}
                  />
                  <ForumTextBox
                    value={newPost.bodyValue}
                    onChange={onNewPostChange("body")}
                    onSubmit={submitPost}
                    error={newPost.bodyErrorMessage.length > 0}
                    errorMessage={newPost.bodyErrorMessage}
                    user={props.user}
                    subTitle="Post body"
                    placeholder="Write your post body here..."
                    CTA="Publish post"
                    maxLength={MAX_POST_LENGTH}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          <Toast show={toasts.postPosted} success>
            Posted Successfully
          </Toast>
          <Toast show={toasts.error} error>
            There was an Error
          </Toast>
          <Toast show={toasts.commentPosted} success>
            Commented Successfully
          </Toast>
        </PageBody>
      </div>
    </>
  )
}

export default Forum
